import React from 'react'
import './about.css'
import ME from '../../assets/sofcheadshot-323 3.jpg'
// import { FaAward } from 'react-icons/fa'
// import {FiUsers} from 'react-icons/fi'
// import {VscFolderLibrary} from 'react-icons/vsc'
const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="abt">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Me"/>
          </div>
        </div>
          <a align="center" href="#contact" className='btn btn-primary'>Contact Me</a>
        </div>

        <div className="about__content">
          <p>
          Hi! I'm Ajay Krishnamurthy, a junior at Duke University, studying Computer Science and Statistics. 
          </p>
          <p>
          I'm particularly drawn to backend and full-stack development, as well as exploring the world of machine learning.
          As a developer, I find joy in crafting code that brings ideas to life. I'm motivated to find efficient solutions and make products that will be useful to people in some facet of their lives.
          </p>
          <p>
          Outside of my interests as a software developer, I'm a huge sports enthusiast. 
          Soccer, basketball, and football, are my big 3, I've been playing and watching them since I was a kid. Every fall, 
          you'll often catch me glued to the TV watching college football and the NFL on the weekends, or playing pickup basketball at the Duke gym.
          What makes sports even cooler for me is how it has teamed up with tech in sports analytics. Seeing how code and computers can optimize and improve decision making in 
          even the most physical pursuits has furthered my passion for becoming a software developer.
          </p>
          <p>
          Feel free to explore my portfolio and get to know more about me and my work.
          </p>
        </div> 
      </div>
    </section>
  )
}

export default About