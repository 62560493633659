import React from 'react'
import './footer.css'
import {FaGithub} from 'react-icons/fa'
import {MdOutlineEmail} from 'react-icons/md'
import { BsLinkedin } from 'react-icons/bs'

const Footer = () => {
  return (
    <footer>
      <h1 className='footer__logo'>Ajay Krishnamurthy</h1>

      <ul className='permalinks'>
      {/* eslint-disable-next-line */}
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socials">
      <a href="mailto:ajayvkrishnamurthy@gmail.com" rel="noreferrer" target="_blank"><MdOutlineEmail/></a>
      <a href="https://github.com/avkrishnamurthy" rel="noreferrer" target="_blank"><FaGithub /></a>
      <a href="https://www.linkedin.com/in/ajay-krishnamurthy-7a3030228/" rel="noreferrer" target="_blank"><BsLinkedin/></a>
      </div>
    </footer>
  )
}

export default Footer