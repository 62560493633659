import React from 'react'
import ProjectDetail from './components/portfolio/ProjectDetail'
import Main from './components/Main'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const App = () => {
  return (
    <>
    <Router>
      <Routes>
      <Route path="/" element={<Main />}/>
      <Route path="/project/:id" element={<ProjectDetail/>}/>
      </Routes>
    </Router>
    </>
    
  )
}
export default App