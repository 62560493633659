import React from 'react'
import data from './data'
import {useParams} from "react-router-dom"
import "./ProjectDetail.css"
const ProjectDetail = () => {
    let {id} = useParams()
  return (

    <div className="project-desc">
        <a id="return" href="/#portfolio"> &lt;- Go back</a>
        <h1 className="project-title">{data[id-1].title}</h1>
        <div>{data[id-1].project_description}</div>
        <br></br>
        <h2>How it works</h2>
        <div>{data[id-1].tech_description}</div>
        <br></br>
        <h2>See it in action</h2>
        <div>
                <a href={data[id-1].demo} target="_blank" rel="noreferrer">Watch the demo</a>
            </div>
        <div>
            <a href={data[id-1].github} target="_blank" rel="noreferrer">See it on GitHub</a>
        </div>
        {data[id-1].link ? (
            <div>
                <a href={data[id-1].link} target="_blank" rel="noreferrer">Try it for yourself</a>
            </div>
        ) : (
            null
        )}
        <div>

        </div>


    </div>
  )
}

export default ProjectDetail