import React from 'react'
import './experience.css'
import {SiAmazonaws, SiRedis, SiCelery, SiDjango, SiFlask, SiJavascript, SiPython, SiPostgresql, SiHeroku} from 'react-icons/si'
import {FaJava, FaReact, FaGithub, FaDocker} from 'react-icons/fa'
import {DiCss3, DiBootstrap} from 'react-icons/di'
import {AiFillHtml5} from 'react-icons/ai'
import {FcLinux} from 'react-icons/fc'
import {BiLogoMongodb} from 'react-icons/bi'
import SQLALCHEMY from '../../assets/sqlalchemy-seeklogo.com.svg'


const Experience = () => {
  return (
    <section id='experience'>
      <h5>What Skills I Have</h5>
      <h2>My Experience</h2>

      <div className="container experience__container">
      <div className="experience_backend">
        <h3>Backend</h3>
          <div className="experience__content">
          <article className="experience__details">
          <SiPython className="experience__details-icon"/>
              <div>
              <h4>Python</h4>
              <small className="text-light">Experienced</small>
              </div>
              </article>

              <article className="experience__details">
              <FaJava className="experience__details-icon"/>
              <div>
              <h4>Java</h4>
              <small className="text-light">Experienced</small>
              </div>
              </article>
              <article className="experience__details">
              <img src="https://i.imgur.com/d0JPOCB.png" alt="C code" className="experience__details-img"></img>
              <div>
              <h4>C</h4>
              <small className="text-light">Experienced</small>
              </div>
              </article>
              <article className="experience__details">
              <SiFlask className="experience__details-icon"/>
              <div>
              <h4>Flask</h4>
              <small className="text-light">Experienced</small>
              </div>
              </article>
            <article className="experience__details">
              {/* <BsPatchCheckFill className="experience__details-icon"/> */}
              <SiPostgresql className="experience__details-icon"/>
              <div>
              <h4>PostgreSQL</h4>
              <small className="text-light">Experienced</small>
              </div>
              </article>

              <article className="experience__details">
              {/* <BsPatchCheckFill className="experience__details-icon"/> */}
              <img src={SQLALCHEMY} alt="sql-alchemy" className="experience__details-img2"></img>
              <div>
              <h4>SQLAlchemy</h4>
              <small className="text-light">Intermediate</small>
              </div>
              </article>
              <article className="experience__details">
              <SiDjango className="experience__details-icon"/>
              <div>
              <h4>Django</h4>
              <small className="text-light">Intermediate</small>
              </div>
              </article>
            <article className="experience__details">
            <SiCelery className="experience__details-icon"/>
              <div>
              <h4>Celery</h4>
              <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
            <SiRedis className="experience__details-icon"/>
              <div>
              <h4>Redis</h4>
              <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
            <SiAmazonaws className="experience__details-icon"/>
              <div>
              <h4>Amazon S3</h4>
              <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
            <FaDocker className="experience__details-icon"/>
              <div>
              <h4>Docker</h4>
              <small className="text-light">Intermediate</small>
              </div>
            </article>

            <article className="experience__details">
            <BiLogoMongodb className="experience__details-icon"/>
              <div>
              <h4>MongoDB</h4>
              <small className="text-light">Beginner</small>
              </div>
            </article>


          </div>     
        </div>
        <div className="experience_frontend">
        <h3>Frontend</h3>
          <div className="experience__content">
          <article className="experience__details">
              <FaReact className="experience__details-icon"/>
              <div>
              <h4>React.js</h4>
              <small className="text-light">Intermediate</small>
              </div>
              </article>
            <article className="experience__details">
              <SiJavascript className="experience__details-icon"/>
              <div>
              <h4>Javascript</h4>
              <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
              <AiFillHtml5 className="experience__details-icon"/>
              <div>
              <h4>HTML</h4>
              <small className="text-light">Intermediate</small>
              </div>
              </article>
            <article className="experience__details">
              <DiCss3 className="experience__details-icon"/>
              <div>
              <h4>CSS</h4>
              <small className="text-light">Intermediate</small>
              </div>
              </article>
            
            <article className="experience__details">
              <DiBootstrap className="experience__details-icon"/>
              <div>
              <h4>Bootstrap</h4>
              <small className="text-light">Beginner</small>
              </div>
              </article>
            
          </div>     
        </div>
        {/*END OF FRONTEND */}

        <div className="experience_misc">
        <h3>Miscellaneous</h3>
          <div className="experience__content">
          <article className="experience__details">
              <FaGithub className="experience__details-icon"/>
              <div>
              <h4>Git/Github</h4>
              <small className="text-light">Experienced</small>
              </div>
              </article>
              <article className="experience__details">
              <FcLinux className="experience__details-icon"/>
              <div>
              <h4>Linux</h4>
              <small className="text-light">Intermediate</small>
              </div>
              </article>
              <article className="experience__details">
            <SiHeroku className="experience__details-icon"/>
              <div>
              <h4>Heroku</h4>
              <small className="text-light">Intermediate</small>
              </div>
            </article>
              
          </div>     
        </div>
      
      </div>
    </section>
  )
}

export default Experience