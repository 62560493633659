import React from 'react'
import RESUME from '../../assets/AjayKrishnamurthyResume.pdf'

const CTA = () => {
  return (
    <div className = "cta">
        <a href={RESUME} target="_blank" rel="noreferrer" className='btn'>Download Resume</a>
        <a href="#contact" className='btn btn-primary'>Contact Me</a>
    </div>
  )
}

export default CTA