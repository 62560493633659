import React from 'react'
import './header.css'
import CTA from './CTA'
// import ME from '../../assets/Subject 8.png'
// import ME from '../../assets/Subject 4.png'
// import ME from '../../assets/sofcheadshot-323 3.jpg'
import ME from '../../assets/IMG_6686 2.jpg'
import Socials from './Socials'

const Header = () => {
  return (
    <header id='header'>
      <div className="container header__container">
        <h5>Hello I'm</h5>
        <h1>Ajay Krishnamurthy</h1>
        <h5 className="text-light">CS & Stats @ Duke University</h5>
        <CTA/>
        <Socials/>
        <div className="me">
          <div className="me-img">
          <img src={ME} alt=""></img>
          </div>
        </div>
        <p className='scroll__down'>Scroll Down</p>

      </div>
    </header>
  )
}

export default Header