import React from 'react'
import './portfolio.css'
import data from "./data.jsx"
const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
  {data.map(({ id, image, title, tools, github, demo, link }) => (
    <article key={id} className="portfolio__item">
      <div className="portfolio__item-image"></div>
      <img className="portfolio-image" src={image} alt={title} />
      <h3 id="title">{title}</h3>
      <p id="tools">Tools: {tools}</p>
      <div className="portfolio__item-cta">
        <a href={github} className='btn' rel="noreferrer" target="_blank">GitHub</a>
        <a href={demo} className='btn btn-primary' rel="noreferrer" target="_blank">Demo</a>
      </div>
      {link ? (
        <div>
          <a id="read-more" href={`project/${id}`} >Read more</a>   +    <a href={link} rel="noreferrer" target="_blank">Try it out!</a>
        </div>
      ) : (
        <a id="read-more" href={`project/${id}`} >Read more</a>
      )}
    </article>
  ))}
</div>
    </section>
  )
}

export default Portfolio