import IMG1 from '../../assets/blitzpicks.png'
import IMG2 from '../../assets/linernotes.png'
import IMG3 from '../../assets/winprob3.png'
import IMG4 from '../../assets/smartcoaster.jpeg'
import IMG5 from '../../assets/frcrobotics.png'
import IMG6 from '../../assets/3D.png'
import IMG7 from '../../assets/BP.jpg'
import IMG8 from '../../assets/nba.png'

const data = [
  {
    id: 1,
    image: IMG1,
    title: "BlitzPicks",
    tools: "Python, Django, REST, JWT, React, Javascript, PostgreSQL, Celery, Redis, Docker, CSS, BeautifulSoup, College Football Data API, Google Search API",
    github: 'https://github.com/avkrishnamurthy/cfb',
    demo: 'https://youtu.be/21Er8juIJAY',
    project_description: 'BlitzPicks is a full-stack web app that I created to serve as an annual competition and community\
                          for college football fans and analysts. The idea for this project came to me over the last few years\
                          while talking to my brother every year about the upcoming college football season\
                          and making our picks on different games and awards. I wanted to make an application that offers an engaging way to make predictions on betting lines and game winners, and also retrospection at the season\'s end, showcasing the most best predictors and unexpected twists that shaped the season. Additionally, I hope for this website to serve as an evaluation tool for predictive college football models\
                          like Bill Connelley\'s SP+, and for a model that I hope to create in the future.\
                          ',
    tech_description: 'The backend is created in Python with the following technologies: \
                       the Django REST Framework was used to create the backend endpoints\
                       and models. JSON Web Tokens (JWT) are used for authentication. Data for the players, games and general team information was fetched\
                       from the College Football Data (CFBD) API. Player images are obtained dynamically using the\
                       Google Search API by querying for headshots of a (player, team) combination. Results are stored in the database to prevent unneccesary calls to the API\
                       for repeat players. The DBMS used was PostgreSQL. Every Tuesday at 4 AM an asynchronous task is run using Celery, Celery Beat, and Redis.\
                       This task fetches game information (scores) from the CFBD API to evaluate and grade predictions made by users\
                       on the past week\'s games. Additionally, every Tuesday at 6 AM another asynchronous task is run with Celery, Celery Beat, and Redis, to fetch\
                       games for the upcoming week with the CFBD API. This was needed because data for betting lines and game times are not always available to start the season.\
                       Each week\'s games are supposed to represent the top 5 games of the week, so to quantify this the task also\
                       does web scraping on ESPN\'s College Football FPI page, and the 5 games with the best average FPI ranking are selected as the games of the week.\
                       There is one more asynchronous task that is run every 5 minutes, once again via Celery, Celery Beat, and Redis. This task locks games.\
                       For every selected game of the week, a lock time is computed which is 1 hour prior to the game start time. \
                       This task is run to lock games at these times so that users can no longer make picks with under an hour to game time.\
                       The frontend was built with React.js and Javascript. The application is not currently deployed, but I aim to deploy it soon.\
                       However, it is fully containerized via docker-compose, so if you want to try it out it\'s very easy - check out the GitHub!\
                      '
  },
  {
    id: 2,
    image: IMG2,
    title: "Liner Notes",
    tools: "Python, Flask, Javascript, SQLAlchemy, PostgreSQL, Jinja2, CSS, Spotify Web API, Heroku",
    github: 'https://github.com/avkrishnamurthy/liner-notes',
    demo: 'https://www.youtube.com/watch?v=BPlUprQ1PSA',
    project_description: 'Liner Notes is a full-stack web app that is a platform for music enthusiasts to share their music reviews with a community.\
                          The idea for this project came from my sister. She is an avid user of the movie reviewing app Letterboxd, and wanted a way to \
                          review music and albums in the same way, and to be able to share that with her friends and other music lovers. I thought it was a great idea\
                          and would be a fun way to share my music taste with my friends and family. As a result, Liner Notes was born, and I started working every day to\
                          create it. A month later, it is online, and being used by friends and family to review albums and show off their favorite songs and albums.\
                          The main function of Liner Notes is to serve as an album reviewing app, where users can rate and review their favorite albums,\
                          sharing them with other users that they can follow. Every user has a custom feed, consisting of reviews made by other users that they follow.\
                          In addition to a user\'s album reviews, albums can be favorited and displayed on your profile as a way to showcase the albums that you love.\
                          Also on your profile will be your top 5 spotify tracks.\
                          ',
    tech_description: 'The backend is created in Python. It uses Flask for the backend logic and endpoints. The DBMS used is\
                       PostgreSQL, a relational database, for efficient data storage and retrieval. The app employs SQLAlchemy, a powerful Object-Relational-Mapping\
                       (ORM) library, for seamless database interaction. The Spotify Web API is integrated to enable features such as album search and user-specific top tracks.\
                       This allows users to search for albums by the artist, utilizing the Spotify API\'s rich music catalog. Spotify\'s authorization\
                       code flow is also implemented to facilitate secure access to users\' Spotify data. The frontend is built with JavaScript to enhance interactivity and manipulate the Document Object Model (DOM).\
                       Web templates were created with Jinja2, and the styling was done with CSS.\
                      ',
    link: 'https://liner-notes-627b78c2d8e8.herokuapp.com/'
  },
  {
    id: 3,
    image: IMG3,
    title: "NBA Win Probability",
    tools: "Python, R, Dash, scikit-learn, Machine Learning, Data viz",
    github: 'https://github.com/avkrishnamurthy/damlWinProb',
    demo: 'https://youtu.be/2kM2T-4UvSk',
    project_description: 'As a part of the Duke Applied Machine Learning club (DAML), I have been working on this project to develop a machine learning model\
                         to evaluate the win probability of NBA games. This project eventually aims to calculate win probabilities on the fly, by scraping data\
                         or finding an API that will give live play-by-play or box score data from NBA games. The model can be trained from the data of previous seasons and used\
                         effectively on live games, which has happened on small scale so far.\
                         ',
    tech_description: 'This project is built in a Python Jupyter Notebook. It uses the scikit-learn package to build a logistic regression model to evaluate the win probability of games from the 2021 NBA season.\
                       The data used was scraped in R from basketball-reference. Dash was integrated to generate dynamic and visually appealing plots for win probability representation\
                       over the course of a game.\
                       '
  },
  {
    id: 4,
    image: IMG4,
    title: "SmartCoaster",
    tools: "C++, Twilio, Particle Photon",
    github: 'https://github.com/avkrishnamurthy/SmartCoaster',
    demo: 'https://www.youtube.com/watch?v=L-6ZjFuQa9M',
    project_description: 'This is a project I completed with a team for the Duke hackathon "Code For Good". Our product was a smart water bottle coaster that tracks how much water a user drinks and alerts the user via text\
                          and visual aid (LEDs) when they are not drinking enough water. To use our product, the user has to place their\
                          water bottle on the coaster and wait. After some time, a Particle Photon microcontroller inside the device will\
                          check the weight of the bottle using a force-sensing resistor to determine if enough water was consumed. If the user\
                          has been drinking enough water, no notification will be sent, and the LEDs will stay off. However, if not enough water has been consumed,\
                          a text notification is sent via Twilio to the user’s phone number, and the LEDs on the coaster will slowly pulse until the user drinks enough water.\
                          Our project won the Health and Wealthness track at the hackathon, and the most creative use of Twilio prize.\
                         ',
    tech_description: 'The main housing of the smart coaster was 3D printed using PLA filament.\
                       The microcontroller was a Particle Photon board. We opted for this instead of an Arduino\
                       for WiFi capability, as we used the Photon to send text message notifications with a webhook and Twilio integration.\
                       The webhook served as the link between the Particle Photon\'s event triggers and the Twilio API, enabling streamlined communication.\
                       We also used LED lights in combination with the text message notifications to alert the user\
                       that they need to drink water. The weight of the water and water bottle were measured using\
                       a force-sensing resistor (FSR). Both the FSR and LED lights were soldered to a perf board\
                       connecting them to the Photon. To convert the sensor reading into a measurable liquid volume,\
                       we took several data points and used linear regression to find the optimal conversion.\
                       In software, we programmed our Photon to take a sample of the FSR data to\
                       determine how much water has been consumed. If we determine that the user hasn’t\
                       had enough water, the Photon will send a text message to the user using Twilio’s SMS API and webhook\
                       and pulse the LEDs until the user grabs the bottle to drink, which we determine by seeing sudden drop\
                       in the FSR reading.\
                      '
  }
  ,{
    id: 5,
    image: IMG5,
    title: "FRC Robotics",
    tools: "Java, PID controllers, Motion profiling",
    github: 'https://github.com/avkrishnamurthy/Robot2021',
    demo: 'https://youtu.be/IIE0kAoQq78',
    project_description: 'This is the source code for my robotics team\'s robot in 2021. As the head programmer, I wrote all the functionality\
                          for the robot\'s climbing, shooting, and movement capabilities. I also wrote the code for the autonomous routines\
                          in the FRC robotics competition, which consisted of several challenges and obstacle courses.\
                          Our robot got first place in North Carolina in the autonomous routine and placed top 5\
                          in the semi-autonomous routines.\
                         ',
    tech_description: 'This project is built in Java, using object-oriented programming (OOP). The code uses the WPILib software,\
                       a comprehensive library tailored for FRC robots to streamline hardware interfacing. Proportional-Integral-Derivative (PID) control algorithms were\
                       also used to achieve precise and responsive control over robot movements.\
                       Motion profiling was also used to generate smooth and accurate trajectories for robot movements, enhancing the consistency and smoothness of autonomous annd driver-operated tasks.\
                       The code also integrates a variety of sensors, such as encoders and gyroscopes, to provide real-time data for accurate navigation and control. These tools were used to develop an effective\
                       system to do autonomous tasks. Things like driver feedback via visual and auditory feedback mechanicsms such as LED indicators were also included in code. Significant testing procedures were\
                       also done, including basic simulation and live testing to fine-tune PID constants, motion profiles, and autonomous routines.\
                      '
  },
  {
    id: 6,
    image: IMG6,
    title: "3D-Visualizer",
    tools: "Python, pygame, pynput, linear algebra",
    github: 'https://github.com/avkrishnamurthy/3DVisualizer',
    demo: 'https://youtu.be/dsEMaCYsBhw',
    project_description: 'This is a project that I created after using CAD for the first time. I was\
                          in my engineering class, and had to learn CAD for a project. I was also taking Linear Algebra\
                          at the same time. Traditional CAD tools are extremely powerful but often require a steep learning curve and lack the simpler interaction I wanted.\
                          This sparked the idea of leveraging my programming and linear algebra skills to create a solution that would make 3D visualization easy.\
                          This project offers a way to translate raw geometric data into interactive and\
                          visually appealing 3D renderings. Whether for educational purposes or creative exploration,\
                          this project opens up new avenues for users to understand and interact with complex 3D structures.\
                         ',

    tech_description: 'This project is built in Python. It uses the numpy package for efficient numerical computations, including vector and matrix operations\
                       necessary for transforming and manipulating 3D coordinates. The project utilizes pynput to capture keyboard inputs and allow users to interactively\
                       control the view and orientation of the rendered 3D object, with click-and drag rotation of the object. It also leverages pygame to create a graphical user interface and rendering environment, with\
                       its capabilities to draw lines connecting vertices, creating the illusion of a 3D object when projected onto a 2D screen. The project also incorporates shading and perspective adjustments\
                       to enhance the visual realism of the 3D object.\
                       '
  },
  {
    id: 7,
    image: IMG7,
    title: "BPSimulator",
    tools: "C++, Arduino",
    github: 'https://github.com/avkrishnamurthy/BPSimulator',
    demo: 'https://youtu.be/atenNfYbQfc',
    project_description: 'This was a team client project with the Duke School of Nursing. The goal was to create an affordable yet effective alternative to costly blood pressure simulator arms. Traditional training arms for practicing blood pressure measurements come with a significant price tag, limiting access for students who require hands-on experience outside the classroom. Our mission was to design a low-cost prototype that not only replicated the experience of taking blood pressures but also provided the flexibility for students to practice at their convenience, which is not possible with the expensive training arms.\
    Our team\'s approach combined mechanical aspects with electrical and software components to deliver a practical prototype that was both functional and user-friendly. We developed a unique blood pressure simulator that provided students with a realistic and interactive learning experience. The prototype allowed students to practice the entire process, from cuff inflation to pressure measurement, while receiving real-time feedback through simulated Korotkoff sounds, which is name given to the sounds that medical personnel listen for when they are taking blood pressure.',
    tech_description: 'The core hardware components of the prototype included an Arduino microcontroller, a force-sensing resistor (FSR) for pressure detection, a 3D-printed arm structure, a speaker, and a button.\
    The prototype was designed to mostly mirror the process of taking an actual blood pressure measurement. The main difference is the setup. Initially, the user would start by placing the blood pressure cuff around the 3D-printed arm. They would then inflate the cuff to the desired systolic pressure, clicking a button to set this pressure as the target. Next, the cuff would be deflated to the desired diastolic pressure, and the button would be clicked again to set this value.\
    This was to correctly calibrate the force-sesnsing resistor, which would otherwise have difficulties with consistency in readings. This essentially establishes a ground-truth, mapping the FSR reading with the true cuff pressure. Then, after fully deflating the cuff, students can then simulate the process of measuring blood pressure by inflating the cuff while listening to the speaker through a stethoscope.\
    The software connected all of these components, programmed in Arduino code, allowing for precise control over the simulation process. The code was programmed to trigger the Korotkoff sounds when the pressure reached the set systolic and diastolic levels. This was achieved by using the data from the force-sensing resistor to detect pressure changes.\
    '
  },
  {
    id: 8,
    image: IMG8,
    title: "NBA Data Analysis",
    tools: "Python, pandas, scipy, Data viz, Statistics",
    github: 'https://github.com/avkrishnamurthy/nba-analysis',
    demo: 'https://youtu.be/1WFLTYAfRQI',
    project_description: 'This is a sports analytics project that aims to use data science to better understand the NBA, and how to optimize playstyle.\
                          As a huge sports enthusiast, this project combined my passion for software with sports.\
                          The first part of the project was to understand how the NBA\'s style of play has changed over the course of its history.\
                          The next part of the project aimed at evaluating if playstyles with a large volume of 3 point shots are associated with winning teams.\
                          Both of these questions were answered with data visualization and statistical analysis tools.\
                          ',
    tech_description: 'This project is built in Python Jupyter Notebook. It uses Pandas for data manipulation\
                       and web scraping to get data for NBA team stats over several decades. The project uses the Matplotlib package for\
                       visualization, to chart a heatmap of the shot distribution of all NBA shots in different years, using coordinate data of shots.\
                       The scipy package is utilized to bolster the trends observed in the visualizations, with t-tests and confidence intervals\
                       to determine that winning teams in the NBA shoot more threes than losing teams.\
                       '
  },
]

export default data;