import React from 'react'
import './nav.css'
import {AiOutlineHome} from 'react-icons/ai'
import {AiOutlineUser} from 'react-icons/ai'
import {BiBook} from 'react-icons/bi'
import {BiMessageSquareDetail} from 'react-icons/bi'
import {GoProjectRoadmap} from 'react-icons/go'
import {useState, useEffect, useRef} from 'react'


const Nav = () => {
  const [activeNav, setActiveNav] = useState('');
  const sectionsRef = useRef([]);

  const handleNavClick = (section) => {
    setActiveNav(section);

    const element = document.querySelector(section);
    if (element) {
      const topOffset = element.offsetTop;
      window.scrollTo({
        top: topOffset,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    sectionsRef.current = ['#header', '#about', '#experience', '#portfolio', '#contact'];

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveNav(`#${entry.target.id}`);
        }
      });
    };

    const observerOptions = {
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver(handleIntersection, observerOptions);

    sectionsRef.current.forEach((section) => {
      const target = document.querySelector(section);
      if (target) {
        observer.observe(target);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <nav>
      <button onClick={() => handleNavClick('#header')} className={activeNav === '#header' ? 'active' : ''}>
        <AiOutlineHome />
      </button>
      <button onClick={() => handleNavClick('#about')} className={activeNav === '#about' ? 'active' : ''}>
        <AiOutlineUser />
      </button>
      <button onClick={() => handleNavClick('#experience')} className={activeNav === '#experience' ? 'active' : ''}>
        <BiBook />
      </button>
      <button onClick={() => handleNavClick('#portfolio')} className={activeNav === '#portfolio' ? 'active' : ''}>
        <GoProjectRoadmap />
      </button>
      <button onClick={() => handleNavClick('#contact')} className={activeNav === '#contact' ? 'active' : ''}>
        <BiMessageSquareDetail />
      </button>
    </nav>
  );
};

export default Nav;
